import { render, staticRenderFns } from "./CrewPersonal.vue?vue&type=template&id=4e2a9c29&scoped=true&"
import script from "./CrewPersonal.vue?vue&type=script&lang=js&"
export * from "./CrewPersonal.vue?vue&type=script&lang=js&"
import style0 from "./CrewPersonal.vue?vue&type=style&index=0&id=4e2a9c29&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e2a9c29",
  null
  
)

export default component.exports