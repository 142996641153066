<template>
  <div>
   <h6 class="font-weight-bolder">Personal Information</h6>
    <div class="row mb-4">
      <div class="col-6">
        <div class="card p-2" >
          <div class="card-title">
            <p class="font-weight-bolder pb-1 mb-0">Crew Information</p>
            <hr class="mt-0 text-dark" >
          </div>
          <div class="card-body p-1">
            <table class="w-100">
              <tr>
                <td style="width: 25%;">Crew IPN:</td>
                <td class="font-weight-bold">{{crewInfo.ipn ? crewInfo.ipn.toUpperCase() : '' }}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Rank:</td>
                <td class="font-weight-bold">{{ crewInfo.rank ? crewInfo.rank.toUpperCase() : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Crew Type:</td>
                <td class="font-weight-bold">{{ crewInfo.crewType ? crewInfo.crewType.toUpperCase() : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Date Hired:</td>
                <td class="font-weight-bold">{{crewInfo.dateHired ? crewInfo.dateHired : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Status:</td>
                <td class="font-weight-bold">{{crewInfo.status ? crewInfo.status : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Manning:</td>
                <td class="font-weight-bold">{{crewInfo.manning ? crewInfo.manning.toUpperCase(): ''}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card mb-2 p-2" >
          <div class="card-title">
            <p class="font-weight-bolder pb-1 mb-0">Benefits & Contribution</p>
            <hr class="mt-0 text-dark" >
          </div>
          <div class="card-body p-1">
            <table class="w-100">
              <template v-if="crewInfo.crewAgencies">
                <tr v-for="benefits in crewInfo.crewAgencies">
                  <td style="width: 25%">{{benefits.agency ? benefits.agency.toUpperCase() : ''}}:</td>
                  <td class="font-weight-bold">{{benefits.number}}</td>
                </tr>
              </template>
            </table>
          </div>
        </div>
        <div class="card p-2" >
          <div class="card-title">
            <p class="font-weight-bolder pb-1 mb-0">Language & Proficiency</p>
            <hr class="mt-0 text-dark" >
          </div>
          <div class="card-body p-1">
            <table class="w-100">
              <template v-if="crewInfo.crewLanguages">
                <tr v-for="language in crewInfo.crewLanguages">
                  <td style="width: 25%">{{language.language ? language.language.toUpperCase() : ''}}:</td>
                  <td>{{language.efficiency ? language.efficiency.toUpperCase() : '' }}</td>
                </tr>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="card p-2" >
          <div class="card-title">
            <p class="font-weight-bolder pb-1 mb-0">GENERAL INFORMATION</p>
            <hr class="mt-0 text-dark" >
          </div>
          <div class="card-body p-1">
            <table class="w-100">
              <tr>
                <td style="width: 25%;">Last Name:</td>
                <td class="font-weight-bold">{{crewInfo.lastName ? crewInfo.lastName.toUpperCase() : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">First Name:</td>
                <td class="font-weight-bold">{{crewInfo.firstName ? crewInfo.firstName.toUpperCase() : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Middle Name:</td>
                <td class="font-weight-bold">{{crewInfo.middleName ? crewInfo.middleName.toUpperCase() : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Suffix:</td>
                <td class="font-weight-bold">{{crewInfo.middleName ? crewInfo.suffix.toUpperCase() : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Other Name:</td>
                <td class="font-weight-bold">{{crewInfo.nickName ? crewInfo.nickName.toUpperCase() : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Gender:</td>
                <td class="font-weight-bold">{{ crewInfo.gender ? crewInfo.gender : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Birthday:</td>
                <td class="font-weight-bold">{{crewInfo.birthDate ? crewInfo.birthDate : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Birth Place:</td>
                <td class="font-weight-bold">{{crewInfo.birthPlace ? crewInfo.birthPlace.toUpperCase() : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Civil Status:</td>
                <td class="font-weight-bold">{{crewInfo.civilStatus ? crewInfo.civilStatus.toUpperCase() : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Nationality:</td>
                <td class="font-weight-bold">{{crewInfo.nationality ? crewInfo.nationality.toUpperCase() : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Religion:</td>
                <td class="font-weight-bold">{{crewInfo.religion ? crewInfo.religion.toUpperCase() : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Height:</td>
                <td class="font-weight-bold">{{crewInfo.height ? crewInfo.height : ''}} cm</td>
              </tr>
              <tr>
                <td style="width: 25%;">Weight:</td>
                <td class="font-weight-bold">{{ crewInfo.weight ? crewInfo.weight : 0}} kg</td>
              </tr>
              <tr>
                <td style="width: 25%;">Hair Color:</td>
                <td class="font-weight-bold">{{ crewInfo.hairColor ? crewInfo.hairColor.toUpperCase() : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Eye Color:</td>
                <td class="font-weight-bold">{{ crewInfo.eyeColor ? crewInfo.eyeColor.toUpperCase() : ''}}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Blood Type:</td>
                <td class="font-weight-bold">{{crewInfo.bloodType  ? crewInfo.bloodType.toUpperCase() : ''}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CrewPersonal",
  computed:{
    ...mapGetters(['crewInfo'])
  }
}
</script>

<style scoped>
.custom-display{
  border: none !important;
  background-color: transparent !important;
  font-weight: normal !important;
  border-radius: 0 !important;
}
.display-height {
  height: 20px !important;
}
table td {
  padding: 2px;
}
</style>
